<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="800"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="ID" v-bind="formItemLayout" v-show="mode=='update'">
        <a-input v-model="form.id" v-decorator="['id']" disabled>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="类型" v-bind="formItemLayout">
        <a-radio-group v-decorator="['type',{initialValue: 1}]" v-model="form.type" disabled>
          <a-radio :value="1">行业</a-radio>
          <a-radio :value="2">岗位</a-radio>
          <a-radio :value="3">职位</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="上级" v-bind="formItemLayout" v-if="form.type>1" prop="parentId">
        <a-select :options="parentList" v-model="form.parentId"
                  v-decorator="['parentId',{rules:[{required:true,message:'上级不能为空'}]}]"></a-select>
      </a-form-model-item>

      <a-form-model-item
          label="状态"
          prop="status"
          v-bind="formItemLayout"
      >
        <!--        <a-input v-model="form.group" v-decorator="['group']" placeholder="标签分组">-->
        <!--        </a-input>-->
        <a-radio-group v-decorator="['status',{initialValue: 1}]" v-model="form.status">
          <a-radio :value="1">有效</a-radio>
          <a-radio :value="0">无效</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="名称" v-bind="formItemLayout" prop="dataName">
        <a-input v-model="form.dataName" v-decorator="['dataName']" placeholder="行业名称，最多20个字" :max-length="20">
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="排序序号" v-bind="formItemLayout">
        <a-input-number v-model="form.sortNumber" v-decorator="['sortNumber']" placeholder="排序序号，越大越优先">
        </a-input-number>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: "ModifyCareerModal",
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 16},
      },
      mode: 'new',
      title: "",
      visible: false,
      confirmLoading: false,
      form: {},
      parentList: [],
      rules: {
        dataName: [
          {
            required: true,
            message: "名称不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    showModal(record, mode) {
      this.mode = mode || 'new'
      this.visible = true;
      this.parentList = [];
      if (this.mode === 'update') {
        this.title = "修改：" + record.dataName;
        this.form = {
          ...record,
        };

        if (record.parentId) {
          this.$get('sysCareer/list', {type: record.type - 1, pageSize: 99999}).then(res => {
            this.parentList = (res.data?.records || []).map(v => {
              return {
                value: v.id,
                label: v.dataName
              }
            })
          })
        }
      } else {
        let targetType = record ? record.type + 1 : 1;
        this.title = "新增" + this.$helper.getDictName(targetType, 'careerType');
        if (record) {
          this.$get('sysCareer/list', {type: record.type, pageSize: 99999}).then(res => {
            this.parentList = (res.data?.records || []).map(v => {
              return {
                value: v.id,
                label: v.dataName
              }
            })
          })
        }
        this.form = {
          type: targetType,
          status: 1,
          parentId: record ? record.id : 0
        }
      }
    },
    handleOk() {
      const _this = this;
      this.$refs.ruleForm.validate((valid, values) => {
        if (valid) {
          if (_this.form.type > 1 && (!_this.form.parentId)) {
            _this.$message.warning("上级不能为空");
            return;
          }
          _this.confirmLoading = true;
          let params = {...this.form};
          delete params.children;
          delete params.parent;

          _this.$postJson("sysCareer/update", params).then((res) => {
            _this.visible = false;
            _this.confirmLoading = false;
            _this.$message.success("保存成功");
            _this.$emit("success", res.data, _this.mode);
          }).catch((e) => {
            console.error(e)
            _this.confirmLoading = false;
          });
        } else {
          _this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}

/deep/ .ant-radio-wrapper-checked {
  color: red;
  font-weight: 700;

  span {
    color: red;
  }
}
</style>
